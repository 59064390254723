import { ErrorMessage, FieldInputProps } from "formik";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import Select from "react-select";
import { SnorclInfo } from "../libs/snorclInfo";
import { ExperimentFormValues } from "./ExperimentForm";
import ToggleButtonGroup from "react-bootstrap/esm/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import Table from "react-bootstrap/esm/Table";

export interface LightBoxFormProps {
    values: ExperimentFormValues,
    phaseIndex: number,
    containerIndex: number,
    snorclInfo: SnorclInfo,
    siteOptions: {
        value: string;
        label: string;
    }[],
    strainOptions: {
        value: string;
        label: string;
    }[]
    getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const LightBoxForm: React.FC<LightBoxFormProps> = (
    {values, snorclInfo, phaseIndex, containerIndex, siteOptions, strainOptions, getFieldProps, setFieldValue}
) => {
    const c = values.phases[phaseIndex].containers[containerIndex];
    const stri = 0;
    const str = c.strains[stri]

    const addStrain = (containerIndex: number) => {
        values.phases[phaseIndex].containers[containerIndex].strains.push({
            name: '',
            replicates: 3
        });
        setFieldValue('phases', [...values.phases]);
    };

    const makeStrainVis = (strains: {name: string, replicates: number}[]) => {
        const maxReplicates = snorclInfo?.sops?.find(sop => sop.name === values.sop)?.replicates || 15;
        const strainVis = strains.reduce((acc, str, stri) => {
            for (let i = 0; i < str.replicates && acc.length < maxReplicates; i = i+1) {
                acc.push(
                    <ToggleButton id={`strVis-${acc.length}`}
                        key={acc.length}
                        value={acc.length}
                        variant={str.name?`${stri%6}`:'empty'}
                    >
                        <OverlayTrigger
                            key={`$${acc.length}-ot`}
                            placement="top"
                            delay={200}
                            overlay={
                                <Tooltip id={`${acc.length}-tt`}>
                                    {str.name || 'Empty'}
                                </Tooltip>
                            }
                        >
                            <div>{acc.length+1}</div>
                        </OverlayTrigger>
                    </ToggleButton>
                );
            }
            return acc
        }, [] as JSX.Element[]);

        for (let i = strainVis.length; i < maxReplicates; i = i + 1) {
            strainVis.push(
                <ToggleButton id={`strVis-${strainVis.length}`}
                        key={strainVis.length}
                        value={strainVis.length}
                        variant='empty'
                    >
                        <OverlayTrigger
                            key={`$${strainVis.length}-ot`}
                            placement="top"
                            delay={200}
                            overlay={
                                <Tooltip id={`${strainVis.length}-tt`}>Empty</Tooltip>
                            }
                        >
                            <div>{strainVis.length+1}</div>
                        </OverlayTrigger>
                    </ToggleButton>
            );
        }

        return strainVis;
    }
    return (<div key={containerIndex}>
        <Row><Col><Card className="light-background" style={{paddingTop: '20px'}}>
            <Container>
            <Row><Col>
                <Form.Group controlId={`phases[${phaseIndex}].containers[${containerIndex}].name.form`}>
                    <Select
                        id={`phases[${phaseIndex}].containers[${containerIndex}].name`}
                        options={siteOptions}
                        required
                        menuShouldScrollIntoView={false}
                        unstyled
                        classNamePrefix='react-select'
                        value={{value: c.name, label: c.name || 'Select...'}}
                        onChange={(newValue, _action) => {
                            if (newValue) {
                                c.name = newValue.value;
                                setFieldValue(`phases[${phaseIndex}].containers[${containerIndex}].name`, newValue.value);
                                setFieldValue('phases', [...values.phases])
                            }
                        }}
                    />
                    <ErrorMessage name={`phases[${phaseIndex}].containers[${containerIndex}].name`}
                        render={msg => <div className="invalid-feedback">{msg}</div>}
                    />
                </Form.Group>
            </Col></Row><br/>
            <div><Row className="justify-content-sm-center">
                <Col></Col>
                <Col  sm='auto'>
                        <ToggleButtonGroup type="checkbox" size='sm'  value={[]} >
                    {
                        makeStrainVis(c.strains)
                    }
                    </ToggleButtonGroup>
                    
                </Col>
                <Col>
                <span  className="danger-icon"
                        hidden={c.strains.reduce((sum, s)=> sum + s.replicates, 0) <= (snorclInfo.sops.find(sop=>sop.name === values.sop)?.replicates??15) }
                    >+{c.strains.reduce((sum, s)=> sum + s.replicates, 0)-(snorclInfo.sops.find(sop=>sop.name === values.sop)?.replicates??15)}</span>
                </Col></Row><br/>
            </div>
            <Row><Col>
                <Table borderless size="sm">
                    <thead>
                        <tr>
                            <th style={{width: '10px'}}></th>
                            <th style={{paddingLeft: '15px', width: 125}} >{'Strain '}&nbsp;
                                <OverlayTrigger
                                    placement="top"
                                    delay={200}
                                    overlay={
                                        <Tooltip id={`toNext-${phaseIndex}-tt`}>Add a strain</Tooltip>
                                    }
                                >
                                    <i className="add-icon"
                                        hidden={values.type==='ALBOUT'}
                                        onClick={() => addStrain(containerIndex)}
                                    ></i>
                                </OverlayTrigger>
                                </th>
                            <th>Replicates</th></tr>
                    </thead>
                    <tbody>
                    {
                        c.strains.map((str, stri) => <tr key={stri}>
                            <td style={{alignContent: 'center', verticalAlign: 'middle'}}>
                                <div className={str.name?`strain-${stri%6}`:'strain-empty'} 
                                    style={{width: '20px', height: '20px', borderRadius: 5}}>
                                </div>
                            </td>
                            <td style={{paddingLeft: '15px', width: 175}} >
                                <Form.Group controlId={`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].name.form`}>
                                    <Select
                                        id={`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].name`}
                                        options={strainOptions}
                                        unstyled
                                        classNamePrefix='react-select'
                                        value={{value: str.name, label: str.name}}
                                        menuShouldScrollIntoView={false}
                                        onChange={(newValue, _action) => {
                                            if (newValue) {
                                                str.name = newValue.value;
                                                setFieldValue(`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].name`, newValue.value);
                                                setFieldValue('phases', [...values.phases])
                                            }
                                        }}
                                    />
                                    <ErrorMessage name={`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].name`}
                                        render={msg => <div className="invalid-feedback">{msg}</div>}
                                    />
                                </Form.Group>
                            </td>
                            <td>
                            <Form.Group controlId={`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].replicates`}>
                                <Form.Control type='number' {...getFieldProps(`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].replicates`)}
                                    max={snorclInfo.sops.find(sop=>sop.name === values.sop)?.replicates??15}
                                    min={1} value={str.replicates}
                                    onChange={e=> {
                                        str.replicates = parseInt(e.target.value);
                                        setFieldValue('phases', [...values.phases]);
                                    }}
                                />
                                <ErrorMessage name={`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].replicates`}
                                    render={msg => <div className="invalid-feedback">{msg}</div>}
                                />
                            </Form.Group>
                            </td>
                        </tr>)
                    }
                    </tbody>
                </Table>
                </Col></Row>
            </Container>
        </Card></Col></Row>
    </div>);
};