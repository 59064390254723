import { ErrorMessage, FieldInputProps } from "formik";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Row from "react-bootstrap/esm/Row";
import Table from "react-bootstrap/esm/Table";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/esm/ToggleButtonGroup";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { AssayType, SnorclInfo } from "../libs/snorclInfo";
import { fromDateInputString, mapToArray, range, toDateInputString } from "../libs/utils";
import { ExperimentFormValues } from "./ExperimentForm";
import Select from "react-select";
import { MAX_PHASE_DAYS } from "./ExperimentForm";
import { GhpsForm } from "./GhpsForm";
import { LightBoxForm } from "./LightBoxForm";
import { MbaRackForm } from "./MbaRackForm";
import { TubeRackForm } from "./TubeRackForm";
import { ScaleUpForm } from "./ScaleUpForm";

export interface PhaseFormProps {
    values: ExperimentFormValues,
    phaseIndex: number,
    snorclInfo: SnorclInfo,
    getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const LB  = 'Light Box';
const SU  = 'Scale-up';
const PS  = 'PondSims';
const INC = 'Incubator';
const PBR = 'VPBRs';
const AssayTypeContainerName: Partial<Record<''|AssayType, string>> = {
    LBA:         LB,
    LBA_SCALEUP: SU,
    MBA:         INC,
    TuBE:        INC,
    "POND SIMS": PS,
    SD_HVST:     PS,
    SD_SCALEUP:  PBR
};

export const PhaseForm: React.FC<PhaseFormProps> = (
    {values, snorclInfo, phaseIndex, getFieldProps, setFieldValue}
) => {
    const calcDates = (): [startDate: string|undefined, endDate: string|undefined] => {
        let totalDays = 0;
        for (let i = 0; i < values.phases.length; i += 1) {
            const phase = values.phases[i]
            if (i === phaseIndex) {
                return [
                    toDateInputString(new Date(
                        values.startDate.getFullYear(),
                        values.startDate.getMonth(),
                        values.startDate.getDate() + totalDays)),
                    toDateInputString(new Date(
                        values.startDate.getFullYear(),
                        values.startDate.getMonth(),
                        values.startDate.getDate() + totalDays + phase.numDays))
                ]
            }
            if (!phase.enabled) {
                continue;
            }

            totalDays += phase.numDays;
        }

        return [undefined, undefined];
    }

    const phase = values.phases[phaseIndex];

    const makeTitle = () => {
        const prefix = values.assay === 'PLANT' ? 'PLANT' : (
            ['LBA_SCALEUP', 'TuBE', 'SD_SCALEUP'].includes(values.assay) ? values.assay : values.type || 'GENERAL'
        );
        let theTitle = prefix+values.num+', '+phase.name+' Phase' +
            ', Replicate '+values.replicate;

        if (values.assay === 'MBA') {
            theTitle += ', ' + values.sop
        }

        return theTitle;
    }

    let   [sd, ed] = calcDates();
    const [endDate, setEndDate] = useState<string|undefined>(ed);
    const [startDate, setStartDate] = useState<string|undefined>(sd);
    const [vesselsTitle, setVesselsTitle] = useState(<h5>Vessels</h5>);
    const [title, setTitle] = useState(makeTitle());
    const DEFALUT_REPLICATES = values.type === 'ALBOUT' ? 1 : 3;

    const addContainer = () => {
        phase.containers.push({
            name: '',
            strains: [{
                name: '',
                replicates: DEFALUT_REPLICATES
            }]
        });
        setFieldValue('phases', [...values.phases]);
    };

    useEffect(
        () => {
            const [_sd, _ed] = calcDates();
            setStartDate(_sd);
            setEndDate(_ed);
        },
        [values.startDate, values.phases]
    );

    useEffect(
        () => {
            setVesselsTitle(<h5>
                {AssayTypeContainerName[values.assay] ?? 'Vessels'} <i
                    className="add-icon"
                    onClick={() => addContainer()}
                    hidden={values.type !== 'ALBOUT'}></i>
                    &nbsp;
                    <OverlayTrigger
                        placement="top"
                        delay={200}
                        overlay={
                            <Tooltip id={`toNext-tt`}>Copy to {values.phases[phaseIndex+1]?.name || 'next phase'}</Tooltip>
                        }
                    >
                        <i className="to-next-icon"
                            hidden={values.type==='ALBOUT' || phaseIndex === values.phases.length-1}
                            onClick={() => {
                                let newBoxes: typeof phase.containers = [];
                                values.phases[phaseIndex].containers.forEach((c, ci) => {
                                    newBoxes.push({
                                        name: c.name,
                                        strains: c.strains.map(cs=>({
                                            name: cs.name,
                                            replicates: cs.replicates
                                        }))
                                    });
                                });
                                values.phases[phaseIndex+1].containers = newBoxes;
                                setFieldValue(`phases`, [...values.phases]);
                            }}
                        ></i>
                    </OverlayTrigger>
            </h5>)
        },
        [values.type, phase, phaseIndex, setFieldValue, values.phases]
    );

    useEffect(
        () => {
            const newTitle = makeTitle()
            setTitle(newTitle);
            phase.title = newTitle;
            setFieldValue(`phases[${phaseIndex}].title`, newTitle);
        },
        [values.replicate, values.num, values.type, phase, phaseIndex, setFieldValue, ]
    )

    

    const siteOptions = snorclInfo.assays[values.assay as AssayType].sites
        .map(s=>({value: s, label: s}))
    // () => 
    // [
    //     <option key='' value='' disabled>Select...</option>,
    //     ...snorclInfo.assays[values.assay as AssayType].sites.map(
    //             s => <option key={s} value={s}>{s}</option>)
    // ]

    const strainOptions = snorclInfo.assays[values.assay as AssayType].strains
        .map(s=>({value: s, label: s, notes: ''}));

    return (<div style={{marginRight: '10px', marginLeft: '10px'}}>
        <Row>
            <Col>
                <Form.Group controlId={`phases[${phaseIndex}].title`}>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        {...getFieldProps(`phases[${phaseIndex}].title`)}
                        type='text' value={title} disabled //disabled={values.sop !== 'Custom'}
                        pattern="^[a-zA-Z0-9 +,_\-]*$"
                    />
                    <ErrorMessage name={`phases[${phaseIndex}].title`} 
                        render={msg => <div className="invalid-feedback">{msg}</div>}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId={`phases[${phaseIndex}].numDays`}>
                    <Form.Label># Days</Form.Label>
                    <Form.Control type='number' {...getFieldProps(`phases[${phaseIndex}].numDays`)}
                        min={1}
                        max={MAX_PHASE_DAYS}
                    />
                    <ErrorMessage name={`phases[${phaseIndex}].numDays`}
                        render={msg => <div className="invalid-feedback">{msg}</div>}
                    />
                </Form.Group>
            </Col>
        </Row><br/><Row>
            <Col xs={6}>
                <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control type='date' 
                        value={endDate}
                        min={startDate}
                        disabled
                    ></Form.Control>
                </Form.Group>
            </Col>
            <Col xs={3}>
                <Form.Group controlId={`phases[${phaseIndex}].inoculationTime`}>
                    <Form.Label>Inoculation Time</Form.Label>
                        <Form.Control type='time'
                            {...getFieldProps(`phases[${phaseIndex}].inoculationTime`)}
                        />
                        <ErrorMessage name={`phases[${phaseIndex}].inoculationTime`}
                            render={msg => <div className="invalid-feedback">{msg}</div>}
                        />
                </Form.Group>
            </Col>
            <Col xs={3}>
                <Form.Group controlId={`phases[${phaseIndex}].sampleTime`}>
                    <Form.Label>Collection Time</Form.Label>
                        <Form.Control type='time'
                            {...getFieldProps(`phases[${phaseIndex}].sampleTime`)}
                        />
                        <ErrorMessage name={`phases[${phaseIndex}].sampleTime`}
                            render={msg => <div className="invalid-feedback">{msg}</div>}
                        />
                </Form.Group>
            </Col>
        </Row><br/>
        <Row>
            <Col>
                <h5>Samples</h5>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card className="light-background justify-content-center" style={{paddingTop: '15px'}} >
                    <Table borderless hover size="sm" style={{width: '100%'}}>
                        <tbody>
                        {
                            phase.samples.map((s, si) => 
                                <tr key={si}>
                                    <td style={{paddingLeft: '15px', width: 125}} >{s.name}</td>
                                    <td>
                                        <ToggleButtonGroup type="checkbox" size='sm'  value={s.collectionDays}
                                            onChange={(v) => {
                                                setFieldValue(`phases[${phaseIndex}].samples[${si}].collectionDays`, v)
                                            }}>
                                                {
                                                    mapToArray(range(phase.numDays + 1), (di =>
                                                        <ToggleButton id={`${s.name}-${phaseIndex}-${di}`}
                                                            key={di}
                                                            value={di}
                                                            variant="outline-primary"
                                                        >
                                                            <OverlayTrigger
                                                                key={`${s.name}-${phaseIndex}-${di}-ot`}
                                                                placement="top"
                                                                delay={200}
                                                                overlay={
                                                                    <Tooltip id={`${s.name}-${phaseIndex}-${di}-tt`}>
                                                                    {
                                                                        new Date(
                                                                            fromDateInputString(startDate!).getFullYear(),
                                                                            fromDateInputString(startDate!).getMonth(),
                                                                            fromDateInputString(startDate!).getDate()+di,
                                                                            parseInt((di === 0 ? phase.inoculationTime : phase.sampleTime).slice(0,2)),
                                                                            parseInt((di === 0 ? phase.inoculationTime : phase.sampleTime).slice(3,5)),
                                                                        ).toLocaleString()
                                                                    }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <div>{di}</div>
                                                            </OverlayTrigger>
                                                        </ToggleButton>
                                                    ))
                                                }
                                        </ToggleButtonGroup>
                                        <ErrorMessage name={`phases[${phaseIndex}].samples[${si}]`}
                                            render={msg => <div className="invalid-feedback">{msg}</div>}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </Card>
            </Col>
        </Row><br/>
        <Row>
            <Col>
                {vesselsTitle}
            </Col>
        </Row>
        {
            phase.containers.map((c, ci) => {
                if (values.assay === 'LBA_SCALEUP') {
                    return (<ScaleUpForm
                        key={ci}
                        values={values}
                        phaseIndex={phaseIndex}
                        containerIndex={ci}
                        siteOptions={[{value: 'FL', label: 'FL'}]}
                        strainOptions={strainOptions}
                        snorclInfo={snorclInfo}
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue} />);
                }
                switch(values.type) {
                    case 'ALBOUT': return (<GhpsForm
                        key={ci}
                        values={values}
                        phaseIndex={phaseIndex}
                        containerIndex={ci}
                        siteOptions={siteOptions}
                        strainOptions={strainOptions}
                        snorclInfo={snorclInfo}
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue} />);
                    case 'BSCBIO': {
                        if ('TuBE' === values.assay) {
                            return (<TubeRackForm
                                key={ci}
                                values={values}
                                phaseIndex={phaseIndex}
                                containerIndex={ci}
                                siteOptions={([{value: 'TB', label: 'TB'}])}
                                strainOptions={strainOptions}
                                snorclInfo={snorclInfo}
                                getFieldProps={getFieldProps}
                                setFieldValue={setFieldValue} />);
                        } else {
                            return (<MbaRackForm
                                key={ci}
                                values={values}
                                phaseIndex={phaseIndex}
                                containerIndex={ci}
                                siteOptions={siteOptions}
                                strainOptions={strainOptions}
                                snorclInfo={snorclInfo}
                                getFieldProps={getFieldProps}
                                setFieldValue={setFieldValue} />);
                            }
                    }
                    default: return (<LightBoxForm
                        key={ci}
                        values={values}
                        phaseIndex={phaseIndex}
                        containerIndex={ci}
                        siteOptions={siteOptions}
                        strainOptions={strainOptions}
                        snorclInfo={snorclInfo}
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue} />);
                }
            })
        }
    </div>);
}