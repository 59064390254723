export default class Map2<K, V> extends Map<K,V> {

    /**
     * If the specified key is not already associated with a value (or is
     * mapped to null), attempts to compute its value using the given mapping
     * function and enters it into this map unless it equals null.
     * @param key key with which the specified value is to be associated
     * @param mappingFunction the mapping function to compute a value
     * @returns the current (existing or computed) value associated with the
     * specified key, which might be null or undefined.
     */
    public computeIfAbsent(
        key: K, 
        mappingFunction: (key: K)=>V
    ): V {
        let value = super.get(key);
        if (null == value) {
            value = mappingFunction(key);
            if (null != value) {
                this.set(key, value);
            }
        }
        return value;
    };

    /**
     * If the specified key is not already associated with a value (or is
     * mapped to a nullish value) associates it with the given value and
     * returns a nullish value, else returns the current value.
     * @param key key with which the specified value is to be associated
     * @param value value to be associated with the specified key
     * @returns the previous value associated with the specified key, or
     * undefined if there was no mapping for the key. (A null return can also
     * indicate that the map previously associated null with the key
     */
    public putIfAbsent(key: K, value: V) {
        let v = this.get(key);
        if (v == null) {
            this.set(key, value);
        }
        return v;
    }
}