import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

export interface WaitingProps {
    message?: string
}

const Waiting: React.FC<WaitingProps> = ({message}: WaitingProps = {}) => {

    message ||= 'Loading...'

    return (
        <Container fluid className="justify-content-center">
            <Row>
                <Col className="centered">
                <img 
                    className="App-logo"
                    src={"/Viridos-Logo_512.png"}
                />
                </Col>
            </Row>
            <Row>
                <Col className="centered text-light">
                    <h2><br/>{message}</h2>
                </Col>
            </Row>
        </Container>
    );
}

export default Waiting;