import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useOktaAuth } from "@okta/okta-react";
import useAuthUser from "../hooks/getUser";
import cfg from "../libs/snorcl-config.json";

const SnorclNavBar = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const userInfo = useAuthUser();
    const loggingIn = async () => oktaAuth.signInWithRedirect({ originalUri: "/" });
    const loggingOut = async () => oktaAuth.signOut();
    const isProd = 0 === 'prod'.localeCompare(cfg.STAGE, 'en', { sensitivity: 'base' });

    return (
        <Navbar bg="light" expand="sm">
            <Container>
                <Navbar.Brand href="/home">SNORCL{isProd ? '' : ` - ${cfg.STAGE}`}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse  id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="justify-content-end">
                    {
                        authState?.isAuthenticated ? (
                            <NavDropdown title={userInfo?.name ?? 'Account'} id="basic-nav-dropdown">
                                <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                                <NavDropdown.Item onClick={loggingOut}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        ) : (
                            <Nav.Link onClick={loggingIn}>Login</Nav.Link>
                        )
                    }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>)
};


export default SnorclNavBar;