import Card from "react-bootstrap/esm/Card";
import useAuthUser from "../hooks/getUser";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";

const Profile = () => {
    const userInfo = useAuthUser();

    return (
        <Container className="justify-content-center">
            <br/><Card>
                <Card.Header>My Profile Details</Card.Header>
                <Card.Body>
                    <Row><Col>Username</Col><Col sm={6}>{userInfo?.preferred_username}</Col></Row>
                    <Row><Col>Email</Col><Col sm={6}>{userInfo?.email}</Col></Row>
                    <Row><Col>Full Name</Col><Col sm={6}>{userInfo?.name}</Col></Row>
                    <Row><Col>Email Verified</Col><Col sm={6}>{userInfo?.email_verified ? "Yes" : "No"}</Col></Row>
                    <Row><Col>Zone</Col><Col sm={6}>{userInfo?.zoneinfo}</Col></Row>
                </Card.Body>
            </Card>
        </Container>
    );
};


export default Profile;