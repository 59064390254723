import { AccessToken } from "@okta/okta-auth-js";
import cfg from "./snorcl-config.json";
import { HttpException } from "./HttpException";

// keep the following types & interfaces in sync with those in backend/src/snorcl_information.ts
export type AssayType = 'MBA' | 'LBA' | 'POND SIMS' | 'PLANT' | 'SD_HVST' |'Custom' |
                        'TuBE' | 'LBA_SCALEUP' | 'SD_SCALEUP';
export type ProjectType = 'ALBIN' | 'ALBOUT' | 'GENERAL' | 'BSCBIO';

export const DEFAULT_SAMPLE_TIME = '12:00';

export interface Phase {
    name: 'Growth' | 'Induction',
    days: number,
    toc: number[],
    tn: number[],
    fame: number[],
    sampleTime: string
    inoculationSampleTime: string
}

export interface SOP {
    name: string,
    assay: AssayType,
    project: ProjectType,
    replicates: number,
    sampleVolumeMl: number,
    phases: [
        growth: Phase,
        induction: Phase
    ]
}

//TODO: SITE SCHEDULE
export interface Assay {
    maxNumber: number,
    sites: string[],
    strains: string[]
}


export interface LimsUser {
    name: string,
    email: string,
    barcode: string
}

export interface SnorclInfo {
    sops: SOP[],
    assays: Record<AssayType, Assay>,
    schedule: Record<string, [startDate: Date, endDate: Date][]>
    limsUsers: LimsUser[]
}

const CACHE_MS = 30 * 60 * 1000;
const KEY_SNORCL_TS   = 'snorclTS';
const KEY_SNORCL_INFO = 'snorclInfo'

export async function getSnorclInfo(accessToken?: AccessToken): Promise<SnorclInfo|null> {

    if (null == accessToken) {
        return null;
    }

    let json: SnorclInfo;

    const lastFetch = parseInt(window.localStorage.getItem(KEY_SNORCL_TS)||'');
    if (isFinite(lastFetch) && (Date.now() - lastFetch < CACHE_MS)) {
        try {
            json = JSON.parse(window.localStorage.getItem(KEY_SNORCL_INFO)||'null')
            if (null != json) {
                console.debug(`using cached data from ${new Date(lastFetch)}`)
            }
        } catch (e) {
            console.error('Could not read snorclData cache from localStorage', e);
        }
    }

    if (null == json!) {
        const response = await fetch(cfg.SNORCL_INFO_URL, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Authentication': `Bearer ${accessToken.accessToken}`
            }
        });

        if (response.status !== 200) {
            throw new HttpException('Snorcl API Error', response.status)
        }

        json = await response.json();
        json.sops.unshift({
            name: 'Custom',
            assay: 'Custom',
            project: 'GENERAL',
            replicates: 15,
            sampleVolumeMl: 2,
            phases: [{
                name: 'Growth',
                days: 5,
                toc: [0],
                tn: [0],
                fame: [0],
                sampleTime: DEFAULT_SAMPLE_TIME,
                inoculationSampleTime: DEFAULT_SAMPLE_TIME
            },{
                name: 'Induction',
                days: 7,
                toc: [0],
                tn: [0],
                fame: [0],
                sampleTime: DEFAULT_SAMPLE_TIME,
                inoculationSampleTime: DEFAULT_SAMPLE_TIME
            }]
        });

        json.assays.Custom = {
            maxNumber: 0,
            sites: Array.from(new Set(Object.values(json.assays).flatMap(assay => assay.sites))).sort(),
            strains: Array.from(Object.values(json.assays).flatMap(assay => assay.strains)).sort().reverse()
        };

        window.localStorage.setItem(KEY_SNORCL_INFO, JSON.stringify(json));
        window.localStorage.setItem(KEY_SNORCL_TS, String(Date.now()));
    }

    Object.keys(json.schedule).forEach(site => {
        const dateRanges = json.schedule[site];

        // dates get serialized as UTC. Convert to local time.
        dateRanges.forEach(dateRange => {
            const sd = new Date(dateRange[0]);
            const ed = new Date(dateRange[1]);
            dateRange[0] = new Date(sd.getUTCFullYear(), sd.getUTCMonth(), sd.getUTCDate());
            dateRange[1] = new Date(ed.getUTCFullYear(), ed.getUTCMonth(), ed.getUTCDate());
        });
    });


    return json;
} 