import { FieldInputProps, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import Tab from "react-bootstrap/esm/Tab";
import Tabs from "react-bootstrap/esm/Tabs";
import { SnorclInfo } from "../libs/snorclInfo";
import { ExperimentFormValues } from "./ExperimentForm";
import { PhaseForm } from "./PhaseForm";


export interface PhasesFormProps {
    values: ExperimentFormValues,
    snorclInfo: SnorclInfo,
    errors: FormikErrors<ExperimentFormValues>,
    getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const PhasesForm: React.FC<PhasesFormProps> = (
    {values, errors, snorclInfo, setFieldValue, getFieldProps}
) => {

    const [phaseTab, setPhaseTab] = useState<string>(values.phases[0]?.name??'');
    const [tabs, setTabs] = useState<JSX.Element[]>([]);

    const resetPhaseTab = () => {
        for(let p of values.phases) {
            if (p.enabled) {
                setPhaseTab(p.name);
                break;
            }
        }
    }

    useEffect(
        () => {
            const newTabCount = values.phases.reduce((total, p) => total + (p.enabled ? 1 : 0), 0);
            setTabs(values.phases.map((p,i) => 
                <Tab key={p.name} eventKey={p.name}
                    title={<span><i hidden={!errors.phases?.[i]} className="danger-icon"> </i>{p.name} <i hidden={newTabCount <= 1} className="close-x" onClick={e=>{
                        e.preventDefault();
                        enablePhaseTab(p.name, false)
                    }}/></span>}
                ><br/>
                    <PhaseForm values={values}
                        phaseIndex={i}
                        snorclInfo={snorclInfo}
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue}
                    />
                </Tab>));
            const selectedPhase = values.phases.find(p => p.name === phaseTab && p.enabled)
            if (!selectedPhase) {
                resetPhaseTab();
            }
        },
        [values.phases, errors]
    );

    const enablePhaseTab = (phaseName: string, enabled: boolean) => {
        console.log(`deleting ${phaseName}`)
        let e = document.getElementById(`phases-tab-${phaseName}`);
        if (e) {
            e.hidden = !enabled;
        }
        for(let p of values.phases) {
            if (p.name === phaseName) {
                p.enabled = enabled;
                break;
            }
        }
        setFieldValue('phases', [...values.phases])
    }

    return (
        <Tabs id='phases'
            activeKey={phaseTab}
            onSelect={k => setPhaseTab(k??'')} 
        >
            { tabs }
        </Tabs>
    );
}