import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Card from "react-bootstrap/esm/Card";
import ListGroup from "react-bootstrap/esm/ListGroup";
import { SnorclInfo, getSnorclInfo } from "../libs/snorclInfo";
import Waiting from "./Waiting";
import { ExperimentForm } from "./ExperimentForm";
import { HttpException } from "../libs/HttpException";

const Home = () => {
    const { authState } = useOktaAuth();
    const [inputHidden, setInputHidden] = useState(false);
    const [expJson, setExpJson] = useState('');
    const [warnings, setWarnings] = useState<any[]>([]);
    const [errors, setErrors] = useState<any[]>([]);
    const [snorclInfo, setSnorclInfo] = useState<SnorclInfo|null>(null);


    useEffect(() => {
        if (authState?.isAuthenticated && null == snorclInfo) {
            getSnorclInfo(authState?.accessToken)
                .then(d => setSnorclInfo(d))
                .catch(e => {
                    console.log(e instanceof Error ? e.message : e);
                    if (e instanceof HttpException) {
                        if ([401, 403].includes(e.code)) {
                            window.location.assign(window.location.origin);
                        }
                        window.location.assign(window.location.origin + '/error');
                    }
                })
        }
    }, [authState]);

    return (<><div className='viridos-gradient'/><br/><Container>
            {authState?.isAuthenticated && authState.accessToken ? (<>
                <Row>
                    <Col>
                        <Card border="warning" hidden={!inputHidden || warnings.length <= 0}>
                            <Card.Header style={{background: "sandybrown"}}>Warnings</Card.Header>
                            <ListGroup variant="flush">
                                {warnings}
                            </ListGroup>
                        </Card><br />
                        <Card border="danger" hidden={!inputHidden || errors.length <= 0}>
                            <Card.Header style={{background: "salmon"}}>Errors</Card.Header>
                            <ListGroup variant="flush">
                                {errors}
                            </ListGroup>
                        </Card><br />
                        <Card border="info" hidden={!inputHidden}>
                            <Card.Header style={{background: "skyblue"}}>JSONs</Card.Header>
                            <pre>{expJson}</pre>
                        </Card>
                    </Col>
                </Row>
                { null == snorclInfo ?
                    <Row>
                        <Col>
                            <Waiting/>
                        </Col>
                    </Row>:<>

                    <Row>
                        <Col>
                         <ExperimentForm snorclInfo={snorclInfo} accessToken={authState.accessToken}/>
                        </Col>
                    </Row>
                    </> 
                }
            </>) : (
                <Row>
                    <Col>
                        <h2 className="centered text-light">Please login to to use the app</h2>
                    </Col>
                </Row>
            )}
    </Container></>);
};


export default Home;