import "bootstrap-icons/font/bootstrap-icons.css";
import { ErrorMessage, FormikBag, FormikErrors, Form as FormikForm, FormikProps, withFormik } from 'formik';
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Form from 'react-bootstrap/esm/Form';
import Row from "react-bootstrap/esm/Row";
import Select from "react-select";
import { ProjectTypes, SampleName, SampleNames } from "../libs/SOP";
import { AssayType, ProjectType, SnorclInfo } from "../libs/snorclInfo";
import { fromDateInputString, toDateInputString, toSnorclTimeString } from "../libs/utils";
import { PhasesForm } from "./PhasesForm";
import NumberRange from "../libs/NumberRange";
import useAuthUser from "../hooks/getUser";
import Modal from "react-bootstrap/esm/Modal";
import { AccessToken } from "@okta/okta-auth-js";
import { LightBox, SnorclJson, SnorclWebRequest, invokeSnorcl } from "../libs/snorclInvoke";
import Map2 from "../libs/Map2";
import cfg from "../libs/snorcl-config.json";

const TITLE_REGEX = /^[a-zA-Z0-9 +,_-]*$/;
const MAX_FUTURE_START_DAYS = 30;
export const MAX_PHASE_DAYS = 40;

export interface PhaseFormValues {
    enabled: boolean,
    name: string,
    title: string,
    numDays: number,
    sampleTime: string,
    inoculationTime: string,
    samples: {
        name: string,
        collectionDays: number[]
    }[],
    containers: {
        name: string,
        strains: {
            name: string,
            replicates: number
            notes?: string
        }[]
    }[]
}

export interface ExperimentFormValues {
    okta_email: string,
    sop: string,
    num: number,
    user_barcode: string,
    type: ProjectType | '',
    assay: AssayType | ''
    startDate: Date,
    replicate: number,
    phases: PhaseFormValues[]
}

export interface ExperimentFormProps {
    snorclInfo: SnorclInfo,
    accessToken: AccessToken
}

const InnerForm: React.FC<ExperimentFormProps & FormikProps<ExperimentFormValues>> = (
    {snorclInfo, values, errors, getFieldProps, getFieldMeta, setFieldValue, isSubmitting, isValid }
) => {

    const userInfo = useAuthUser();
    const sops = snorclInfo.sops;
    const [minNum, setMinNum] = useState(1);
    const userOptions = snorclInfo.limsUsers.map(u => ({value: u.barcode, label: u.name}));
    const [show, setShow] = useState(false);
    const isProd = 0 === 'prod'.localeCompare(cfg.STAGE, 'en', { sensitivity: 'base' });

    useEffect(() => {
        if (userInfo?.email && (!values.user_barcode || 'EM210' === values.user_barcode)) {
            setFieldValue('okta_email', userInfo?.email)
            let oktaEmail = userInfo?.email?.substring(0, userInfo?.email?.indexOf('@') + 1);
            if (oktaEmail) {
                let usr = snorclInfo.limsUsers.find(u => u.email?.toLocaleLowerCase()?.startsWith((oktaEmail as string).toLowerCase()));
                if (usr?.barcode) {
                    setFieldValue('user_barcode', usr.barcode);
                }
            }
        }
    }, [userInfo, snorclInfo, setFieldValue, values.user_barcode])

    useEffect(() => {
        if (isSubmitting && isValid) {
            setShow(true)
        }
    }, [isSubmitting, isValid])

    return (<FormikForm>
        <Modal
            show={show}
            backdrop='static'
            centered
            keyboard={false}
            onHide={() => {setShow(false); window.location.reload();}}
        >
            <Modal.Header><Modal.Title>Submitting Request...</Modal.Title></Modal.Header>
            <Modal.Body>
                The request is being submitted. The results will be sent to <em>{userInfo?.email}</em>.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary"
                    disabled={isSubmitting}
                    onClick={() => {setShow(false); window.location.reload();}}
                >
                    {isSubmitting ? 'Submitting...' :'Close'}
                </Button>
            </Modal.Footer>
        </Modal>
        <Row>
            <Col>
                <Card>
                    <Card.Header>{values.type.toUpperCase()+values.num}</Card.Header>
                    <Card.Body>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group controlId="sop">
                                        <Form.Label>SOP</Form.Label>
                                        <Form.Select {...getFieldProps('sop')} autoFocus required
                                            onChange={(e)=>{
                                                e.preventDefault();
                                                e.target.disabled = true;
                                                let newSop =sops.find(s=>s.name===e.target.value);
                                                if (newSop) {
                                                    setFieldValue('type', newSop.project);
                                                    setFieldValue('sop', newSop.name);
                                                    setFieldValue('assay', newSop.assay);
                                                    if (!getFieldMeta('num').touched) {
                                                        setFieldValue('num', (snorclInfo.assays[newSop.assay].maxNumber || 998) + 1)
                                                        setMinNum((snorclInfo.assays[newSop.assay].maxNumber || 0) + 1)
                                                    }

                                                    let newPhases: ExperimentFormValues['phases'] = [];
                                                    newSop.phases.filter(p => p.days).forEach(p => {
                                                        newPhases.push({
                                                            enabled: true,
                                                            name: p.name,
                                                            title: (newSop?.project??'') + values.num + ', ' + p.name + ' Phase' +
                                                                ', Replicate '+ values.replicate,
                                                            numDays: p.days,
                                                            sampleTime: p?.sampleTime??'12:00',
                                                            inoculationTime: p.inoculationSampleTime??'12:00',
                                                            samples: [
                                                                {name: 'TOC',  collectionDays: p.toc},
                                                                {name: 'TN',   collectionDays: p.tn},
                                                                {name: 'Fame', collectionDays: p.fame}
                                                            ],
                                                            containers: [{
                                                                name: '',
                                                                strains: [{
                                                                    name: '',
                                                                    replicates: newSop!.project === 'ALBOUT' ? 1 : 3,
                                                                    notes: ''
                                                                }]
                                                            }]
                                                        });
                                                    });
                                                    setFieldValue('phases', newPhases);
                                                    document.getElementById('num')?.focus();
                                                    if (newSop.name === 'Custom') {
                                                        (document.getElementById('type') as HTMLSelectElement).disabled = false;
                                                    }
                                                }
                                                // getFieldProps('sop').onChange(e);
                                            }}
                                        >
                                            {[
                                                <option key='' value='' disabled>Select an SOP...</option>,
                                                ...sops.map(s => <option key={s.name} value={s.name}>{s.name}</option>)
                                            ]}
                                        </Form.Select>
                                        <ErrorMessage name="sop" />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group controlId="num">
                                        <Form.Label>Experiment Number</Form.Label>
                                        <Form.Control type='number' {...getFieldProps('num')} 
                                            onChange={e => {
                                                setFieldValue('num', e.target.value);
                                                // hack to get the vessel section title to update. useEffect on values.num didn't work
                                                setFieldValue('phases', [...values.phases]);
                                            }}
                                            min={minNum}
                                        />
                                        <ErrorMessage name="num" render={msg => <div className="invalid-feedback">{msg}</div>} />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group controlId="user_barcode">
                                        <Form.Label>LIMS User</Form.Label>
                                        <Select
                                            id='user_barcode'
                                            options={userOptions}
                                            required
                                            menuShouldScrollIntoView={false}
                                            unstyled
                                            classNamePrefix='react-select'
                                            value={{
                                                value: values.user_barcode,
                                                label: userOptions.find(u => u.value === values.user_barcode)?.label || 'Select LIMS User...'
                                            }}
                                            onChange={(newValue, _action) => {
                                                    console.log('new lims user', newValue)
                                                if (newValue) {
                                                    values.user_barcode = newValue.value
                                                    setFieldValue('user_barcode', newValue.value)
                                                }
                                            }}
                                        />
                                        <ErrorMessage name='user_barcode'
                                            render={msg => <div className="invalid-feedback">{msg}</div>}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row><br/>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group controlId="type">
                                        <Form.Label>Experiment Type</Form.Label>
                                        <Form.Select  {...getFieldProps('type')} disabled
                                            onChange={e => {
                                                values.type = e.target.value as ProjectType
                                                setFieldValue('type', e.target.value);
                                                e.target.disabled = true;
                                                // hack to get the vessel section title to update. useEffect on values.type didn't work
                                                setFieldValue('phases', [...values.phases]);
                                            }}>
                                            {[
                                                <option key='' value='' disabled>Select a Experiment Type...</option>,
                                                ...ProjectTypes.map(pt => <option key={pt} value={pt}>{pt}</option>)
                                            ]}
                                        </Form.Select>
                                        <ErrorMessage name="type" render={msg => <div className="invalid-feedback">{msg}</div>} />
                                    </Form.Group>
                                </Col>
                                <Col xs={values.assay === 'POND SIMS' ? 3 : 6}>
                                    <Form.Group controlId='startDate'>
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control type='date'
                                            {...getFieldProps('startDate')}
                                            onChange={e => {
                                                setFieldValue('startDate', fromDateInputString(e.target.value));
                                                // hack to get the end dates to update. useEffect on values.startDate didn't work
                                                setFieldValue('phases', [...values.phases]);
                                            }}
                                            value={toDateInputString(values.startDate)}
                                            min={toDateInputString(new Date())}
                                            max={toDateInputString(new Date(new Date().setDate(new Date().getDate() + MAX_FUTURE_START_DAYS)))}
                                        ></Form.Control>
                                       <ErrorMessage name="startDate" render={msg => <div className="invalid-feedback">{msg}</div>} />
                                    </Form.Group>
                                </Col>
                                <Col xs={3} hidden={values.assay !== 'POND SIMS' }>
                                    <Form.Group controlId='replicate'>
                                        <Form.Label>Replicate Number</Form.Label>
                                        <Form.Control type='number' {...getFieldProps('replicate')}
                                            onChange={e => {
                                                setFieldValue('replicate', parseInt(e.target.value) || 1);
                                                // hack to get the vessel section title to update. useEffect on values.replicate didn't work
                                                setFieldValue('phases', [...values.phases]);
                                            }}
                                            min={1}
                                        />
                                        <ErrorMessage name="replicate" render={msg => <div className="invalid-feedback">{msg}</div>} />
                                    </Form.Group>
                                </Col>
                            </Row><br/>
                            <Row hidden={values.sop===''}>
                                <Col>
                                    <PhasesForm values={values}
                                        snorclInfo={snorclInfo}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        getFieldProps={getFieldProps}
                                    />
                                </Col>
                            </Row>
                            <Row hidden={isProd || values.sop===''}>
                                <Col>
                                    <div style={{ textAlign: 'center' }}>
                                        Please only use strains 3164* (and 31637 for PondSims) in the dev environment
                                    </div>
                                </Col>
                            </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row><br/>
        <Row>
            <Col></Col>
            <Col sm='auto'><Button variant='outline-warning' 
                onClick={(e)=>{
                    console.log(`e.shiftKey ==`, e.shiftKey)
                    if (e.shiftKey) {
                        window.localStorage.removeItem('snorclInfo');
                    }
                    window.location.reload();
                }}
                
            >Start Over</Button></Col>
            {/* This button is here to stop the behavior of the 'Enter' key in input fields subitting the form */}
            <button type="submit" disabled style={{display: 'none'}} aria-hidden="true"></button>
            <Col sm='auto'><Button variant='outline-primary' type='submit' disabled={isSubmitting}>Submit</Button></Col>
        </Row>
    </FormikForm>);
}

const DecoratedForm = withFormik<ExperimentFormProps, ExperimentFormValues>({

    mapPropsToValues: (
        props: ExperimentFormProps
    ):ExperimentFormValues => {
        return {
            okta_email: '',
            sop: '',
            user_barcode: 'EM210',
            num: 999,
            type: '',
            assay: '',
            startDate: new Date(),
            replicate: 1,
            phases: []
        }
    },

    handleSubmit : async (
        values:    ExperimentFormValues, 
        {props}: FormikBag<ExperimentFormProps, ExperimentFormValues>
    ): Promise<void> => {
        console.log('submitting:', values);
        const SOP = props.snorclInfo.sops.find(sop => sop.name === values.sop);
        const maxReplicates = SOP?.replicates || 15;
        const inputs: SnorclJson[] = [];

        values.phases.forEach((phase, phaseIndex) => {

            if (!phase.enabled) {
                return;
            }
            const sample_volume_ml = SOP?.sampleVolumeMl ?? 2;
            if (phase.sampleTime === phase.inoculationTime) {
                makeSnorclInput(inputs, values, phaseIndex, 'all', maxReplicates, sample_volume_ml)
            } else {
                makeSnorclInput(inputs, values, phaseIndex, 'inoculation', maxReplicates, sample_volume_ml)
                makeSnorclInput(inputs, values, phaseIndex, 'non-inoculation', maxReplicates, sample_volume_ml)
            }
        });

        const snorclWebRequest: SnorclWebRequest = {
            oktaUser: values.okta_email,
            snorclInputs: inputs
        }

        console.log(snorclWebRequest);
        try {
            await invokeSnorcl(snorclWebRequest, props.accessToken);
        } catch (reason) {
            console.error(reason);
            alert(`The submission request returned an error response.  Please ask the SNAP team for assistance.`)
        }
    },

    validate: (
        values: ExperimentFormValues,
        props: ExperimentFormProps
    ): FormikErrors<ExperimentFormValues> => {
        console.log('validating: ', values);
        let errors: FormikErrors<ExperimentFormValues> = {};

        if (!ProjectTypes.includes(values.type as ProjectType)) {
            errors.type = 'Type is requred'
        }

        if (!values.user_barcode) {
            errors.user_barcode = 'LIMS User is required';
        }

        const minNum = props.snorclInfo.assays[values.assay as AssayType]?.maxNumber || 0;
        let num = parseInt(String(values.num));
        if (!isFinite(num) || num <= minNum) {
            errors.num = `Must be an integer greater than ${minNum}`;
        }
        let replicate = parseInt(String(values.replicate));
        if (!isFinite(replicate) || replicate <= 0) {
            errors.replicate = `Must be a positive integer`;
        }
        let minStart = new Date();
            minStart.setHours(0);
            minStart.setSeconds(0);
            minStart.setMinutes(0);
            minStart.setMilliseconds(0);
        let maxStart = new Date(minStart.getTime());
            maxStart.setDate(maxStart.getDate()+ MAX_FUTURE_START_DAYS);
        let startDate = new Date(values.startDate);
        if (isNaN(startDate.valueOf()) || startDate < minStart || startDate > maxStart) {
            errors.startDate = `Choose a date up to ${MAX_FUTURE_START_DAYS} days in the future`;
        }

        let pStartOffset = 0;
        const phasesErrors: FormikErrors<PhaseFormValues>[] = [];
        values.phases.forEach((phase, pi) => {
            if (!phase.enabled) {
                return;
            }
            const phaseErrors: FormikErrors<PhaseFormValues> = {};

            if (!TITLE_REGEX.test(phase.title)) {
                phaseErrors.title = 'only alpha-numeric, underscore, dash, comma, and plus are allowed';
            }
            const title = (phase.title||'').toUpperCase();
            const searchStr = values.assay === 'PLANT' ? 'PLANT' : (
                ['LBA_SCALEUP', 'TuBE', 'SD_SCALEUP'].includes(values.assay) ? values.assay.toUpperCase() : values.type.toUpperCase()
            );

            if (!title.includes(searchStr)) {
                phaseErrors.title = `Title should contain '${searchStr}'`;
            } else if (!title.includes(String(values.num))) {
                phaseErrors.title = 'Title should contain the experiment number';
            } else if (!title.includes(phase.name.toUpperCase())) {
                phaseErrors.title = 'Title should contain the phase name';
            } else if (title.indexOf(',') <= 0) {
                phaseErrors.title = 'Title should hava a comma between experiment and phase. E.g. "ALIBN42, Growth Phase"';
            }

            if (!isFinite(phase.numDays) || phase.numDays < 0 || MAX_PHASE_DAYS < phase.numDays) {
                phaseErrors.numDays = `# Days must be an integer between 0 and ${MAX_PHASE_DAYS}`;
            }
            let [h,m,s] = (phase.sampleTime||'').split(':').map(str=>parseInt(str));
            if (null == h || null == m || h < 0 || h >=24 || m < 0 || m >= 60
                || (null != s && (s < 0 || s >= 60)))
            {
                phaseErrors.sampleTime = 'Invalid time'
            }

            const sampleErrors: string[] = [];
            phase.samples.forEach((s, _si) => {
                let err = '';
                if (!SampleNames.includes(s.name as SampleName)) {
                    err = 'Unrecognized sample name';
                }
                for (const collectionDay of s.collectionDays) {
                    if (collectionDay < 0) {
                        err = 'Bad Collection Day';
                        break;
                    }
                }
                if (s.collectionDays.length !== new Set(s.collectionDays).size) {
                    err = 'Duplicate Collection Day';
                }
                if (err) {
                    sampleErrors[_si] = err;
                }
            });
            if (sampleErrors.length) {
                phaseErrors.samples = sampleErrors;
            }

            type ContainerError = FormikErrors<{
                name: string;
                strains: {
                    name: string;
                    replicates: number;
                    notes?: string;
                }[];
            }>
            const containersErrors: ContainerError[] = [];
            phase.containers.forEach((c, ci) => {
                const containerErrors: ContainerError = {};
                if (values.assay === 'LBA_SCALEUP') {
                        if (c.name !== 'FL') {
                        phaseErrors.title = 'The container name is not "FL"';
                    }
                } else if (values.assay === 'TuBE') {
                    if (c.name !== 'TB') {
                        phaseErrors.title = 'The container name is not "TB"';
                    }
                } else if (!props.snorclInfo.assays[values.assay as AssayType].sites.includes(c.name)) {
                    containerErrors.name = 'Make a selection'
                }

                const cSched = (props.snorclInfo.schedule[c.name] ?? []).map(([sd, ed]) => 
                    new NumberRange(sd.getTime(), new Date(ed.getFullYear(), ed.getMonth(), ed.getDate()+1).getTime())
                );
                if (cSched.length) {
                    const phaseRange = new NumberRange(
                        new Date(values.startDate.getFullYear(),
                                 values.startDate.getMonth(),
                                 values.startDate.getDate() + pStartOffset).getTime(),
                        new Date(values.startDate.getFullYear(),
                                 values.startDate.getMonth(),
                                 values.startDate.getDate() + pStartOffset + phase.numDays + 1).getTime()
                    );
                    // console.log(`${phase.name}:${c.name} ${new Date(phaseRange.start).toDateString()}-${new Date(phaseRange.end).toDateString()}`)
                    for(let schedRange of cSched) {
                        if (schedRange.intersects(phaseRange)) {
                            let d = new Date(schedRange.end)
                            containerErrors.name = `${c.name} is already scheduled ${new Date(schedRange.start).toDateString()}-${
                                new Date(d.getFullYear(), d.getMonth(), d.getDate() -1).toDateString()}`;
                            break;
                        }
                    }
                }

                type StrainError = FormikErrors<{
                    name: string;
                    replicates: number;
                    notes?: string;
                }>;
                const strainsErrors: StrainError[] = [];
                const maxReplicates = props.snorclInfo.sops.find(sop => sop.name === values.sop)?.replicates??0;
                let totalReplicates = 0;
                c.strains.forEach((str, stri) => {
                    const strainErrors: StrainError = {};
                    if (!props.snorclInfo.assays[values.assay as AssayType].strains.includes(str.name)) {
                        strainErrors.name = 'Make a selection';
                    }

                    for (let otherPhase of values.phases) {
                        if (otherPhase.enabled && otherPhase.name !== phase.name) {
                            if (!otherPhase.containers.flatMap(opc => opc.strains.map(opcs => opcs.name)).includes(str.name)) {
                                strainErrors.name = `${otherPhase.name} does not include ${str.name}`;
                                break;
                            }
                        }
                    }

                    if (isNaN(str.replicates)) {
                        str.replicates = 0;
                        strainErrors.replicates = 'Enter the number of replicates';

                    } else if (str.replicates < 1 || (maxReplicates < str.replicates)) {
                        strainErrors.replicates = `Replicates must be between 1 and ${maxReplicates}`;
                    }

                    totalReplicates += str.replicates;

                    if (stri === c.strains.length -1 && totalReplicates > maxReplicates) {
                        strainErrors.replicates = `Total replicates must not exceed ${maxReplicates}`;
                    }
                    if (Object.keys(strainErrors).length) {
                        strainsErrors[stri] = strainErrors;
                    }
                });
                if (strainsErrors.length) {
                    containerErrors.strains = strainsErrors;
                }
                if (Object.keys(containerErrors).length) {
                    containersErrors[ci] = containerErrors;
                }
            });
            if (containersErrors.length) {
                phaseErrors.containers = containersErrors;
            }
            if (Object.keys(phaseErrors).length) {
                phasesErrors[pi] = phaseErrors;
            }
            pStartOffset += phase.numDays;
        });
        if (phasesErrors.length) {
            errors.phases = phasesErrors;
        }
        // console.log(errors)
        return errors;
    },
    validateOnBlur: false,
    validateOnChange: false, validateOnMount: true

})(InnerForm);

const makeSnorclInput = (
    inputs: SnorclJson[],
    values: ExperimentFormValues,
    phaseIndex: number,
    sampleDays: 'all' | 'inoculation' | 'non-inoculation',
    maxReplicates: number,
    sample_volume_ml: number=2
): void => {
    let phase = values.phases[phaseIndex];
    let expName = phase.title + (sampleDays === "inoculation" ? ' T0' : '');
    let startDate = values.startDate;
    for (let i = 0; i < phaseIndex; i++) {
        let otherPhase = values.phases[i];
        if (!otherPhase.enabled) {
            continue;
        }
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + otherPhase.numDays);
    }
    let endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + phase.numDays);
    const light_boxes: LightBox[] = [];
    const json: SnorclJson = {
        user_barcode: values.user_barcode,
        project: values.type,
        sample_time: "12:00",
        sample_volume_ml,
        exp: {
            name : expName,
            light_boxes
        }
    }

    const samples: any = {};
    phase.samples.forEach((sample, sampleIndex) => {
        const sampleKey = `${sample.name.toLowerCase()}_date_range`;
        const scheduled_days: number[] = [];
        const rangeStart = sampleDays === 'non-inoculation' ? 1 : 0;
        const rangeEnd   = sampleDays === 'inoculation' ? 0 : phase.numDays;
        for (const d of sample.collectionDays) {
            if (rangeStart <= d && d <= rangeEnd) {
                scheduled_days.push(d);
            }
        }
        if (scheduled_days.length) {
            samples[sampleKey] = {
                start_date: `${String(startDate.getMonth()+1).padStart(2, '0')}/${String(startDate.getDate()).padStart(2, '0')}/${startDate.getFullYear()}`,
                end_date: `${String(endDate.getMonth()+1).padStart(2, '0')}/${String(endDate.getDate()).padStart(2, '0')}/${endDate.getFullYear()}`,
                scheduled_days: scheduled_days.sort((a,b)=>parseInt(String(a))-parseInt(String(b)))
            }
        }
    });

    if (!Object.keys(samples).length) {
        return;
    }

    if (values.type === 'ALBOUT' && ['POND SIMS', 'SD_SCALEUP'].includes(values.assay)) {
        // hack to reduce the number of ACRs. make one lightbox and the slot represents the GHPS
        const siteRegEx = /((?:RDPS)|(?:GHPS)|(?:VPBR))(\d+)/;
        const lightBoxes: Map2<string,LightBox> = new Map2();
        phase.containers.forEach((c, cIndex) => {
            const [,psType, slotNum] = siteRegEx.exec(c.name)!;
            const light_box = lightBoxes.computeIfAbsent(psType, name => ({
                name,
                strain_barcodes: Array(12).fill('empty'),
                strain_conditions: new Array(12).fill('')
            }));
            // slotNum is one-based, strain_barcodes is zero-based
            light_box.strain_barcodes[parseInt(slotNum)-1] = c.strains[0].name; // ignore replicates.
        });

        lightBoxes.forEach(light_box => {
            Object.assign(light_box, samples);
            json.exp.light_boxes.push(light_box);
        });

    } else {
        phase.containers.forEach((c, cIndex) => {
            const strain_barcodes:   string[] = [];
            const strain_conditions: string[] = [];
            const light_box: LightBox = {
                name: c.name,
                strain_conditions,
                strain_barcodes
            };
            c.strains.forEach((str, strIndex) => {
                for (let r = 0; r < str.replicates; r = r + 1) {
                    strain_barcodes.push(str.name);
                    strain_conditions.push(str.notes||'');
                }
            });

            while(strain_barcodes.length < maxReplicates) {
                strain_barcodes.push('empty');
            }

            while(strain_conditions.length < maxReplicates) {
                strain_conditions.push('');
            }

            light_box.strain_barcodes = strain_barcodes;
            Object.assign(light_box, samples)
            json.exp.light_boxes.push(light_box);
        });
    }

    json.sample_time = toSnorclTimeString(sampleDays === 'inoculation' ? phase.inoculationTime : phase.sampleTime);

    inputs.push(json);
}

/**
 * The Form card and submit button
 */
export const ExperimentForm: React.FC<ExperimentFormProps> = (
    {snorclInfo, accessToken}
) => {
    // const [modalClosable, setModalClosable] = useState(false);
    return (
        <DecoratedForm
            snorclInfo={snorclInfo}
            accessToken={accessToken}
        />
    )
}