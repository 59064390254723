import { ErrorMessage, FieldInputProps } from "formik";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import Select from "react-select";
import { SnorclInfo } from "../libs/snorclInfo";
import { ExperimentFormValues } from "./ExperimentForm";

export interface GhpsFormProps {
    values: ExperimentFormValues,
    phaseIndex: number,
    containerIndex: number,
    snorclInfo: SnorclInfo,
    siteOptions: {
        value: string;
        label: string;
    }[],
    strainOptions: {
        value: string;
        label: string;
    }[]
    getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const GhpsForm: React.FC<GhpsFormProps> = (
    {values, snorclInfo, phaseIndex, containerIndex, siteOptions, strainOptions, getFieldProps, setFieldValue}
) => {
    const c = values.phases[phaseIndex].containers[containerIndex];
    const stri = 0;
    const str = c.strains[stri]
    return (<div key={containerIndex}>
        <Row><Col><Card className="light-background" style={{paddingTop: '20px'}}>
            <Container>
                <Row><Col>{values.assay === 'SD_SCALEUP' ? 'VPBR' : 'Pond'}</Col><Col>Strain</Col></Row>
                <Row><Col>
                    <Form.Group controlId={`phases[${phaseIndex}].containers[${containerIndex}].name.form`}>
                        <Select
                            id={`phases[${phaseIndex}].containers[${containerIndex}].name`}
                            options={siteOptions}
                            required
                            menuShouldScrollIntoView={false}
                            unstyled
                            classNamePrefix='react-select'
                            value={{value: c.name, label: c.name || 'Select...'}}
                            onChange={(newValue, _action) => {
                                if (newValue) {
                                    c.name = newValue.value;
                                    setFieldValue(`phases[${phaseIndex}].containers[${containerIndex}].name`, newValue.value);
                                    setFieldValue('phases', [...values.phases])
                                }
                            }}
                        />
                        <ErrorMessage name={`phases[${phaseIndex}].containers[${containerIndex}].name`}
                            render={msg => <div className="invalid-feedback">{msg}</div>}
                        />
                    </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].name.form`}>
                            <Select
                                id={`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].name`}
                                options={strainOptions}
                                unstyled
                                classNamePrefix='react-select'
                                value={{value: str.name, label: str.name}}
                                menuShouldScrollIntoView={false}
                                onChange={(newValue, _action) => {
                                    if (newValue) {
                                        str.name = newValue.value;
                                        setFieldValue(`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].name`, newValue.value);
                                        setFieldValue('phases', [...values.phases])
                                    }
                                }}
                            />
                            <ErrorMessage name={`phases[${phaseIndex}].containers[${containerIndex}].strains[${stri}].name`}
                                render={msg => <div className="invalid-feedback">{msg}</div>}
                            />
                        </Form.Group>
                    </Col>
                </Row><br/>
            </Container>
        </Card></Col></Row>
        <br/>
    </div>);
};