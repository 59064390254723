import Card from "react-bootstrap/esm/Card";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";

const Error = () => {

    return (
        <Container className="justify-content-center">
            <br/><Card border="warning">
                <Card.Header style={{background: "#FDF2D1"}}>SNORCL API Error</Card.Header>
                <Card.Body>
                    SNORCL data could not be retreived from the database.  Please wait a few minutes and try again.
                </Card.Body>
            </Card>
            <br/>
            <Row>
                <Col></Col>
                <Col sm='auto'>
                    <Button variant='outline-primary' 
                        onClick={()=>window.location.assign(window.location.origin)}
                    >Start Over</Button>
                </Col>
            </Row>
        </Container>
    );
};


export default Error;