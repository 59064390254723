/**
 * matches a string representing a number line segment, like `[-.15, 0)` or`(,10)`
 * `[` and `]` represent closed (inclusive) endpoints; `(` and `)` open.
 *
 * If omitted, then `[` and `)` are assumed, unless the starting point is unbounded,
 * in which case it will be open.
 * Missing endpoints represent an unbounded range (-Infinity or Infinity) and must be
 * open.
 * Regex group names are `startInc`, `start`, `end`, and `endInc`.
 */
const rangeRegEx = /^(?<sartInc>\[|\()?(?<start>(?:[+-])?\d*(?:.\d+)?),\s*(?<end>(?:[+-])?\d*(?:.\d+)?)(?<endInc>]|\))?$/;
const compareNumbers = (a:number, b: number) => a === b ? 0 : a < b ? -1 : 1;
const lt  = (a:number, b: number) => a <  b;
const lte = (a:number, b: number) => a <= b;

/** A set of numbers that contains all numbers lying between any two numbers of the set */
export default class NumberRange
{
    startInclusive = true;
    endInclusive   = false;

    start = -Infinity;
    end   =  Infinity;

    /**
     * Empty ranges are recognized and always result in (0,0); This happens when
     * start > end, or start == end and either endpoint is open.
     * @param start Defaults to -Infinity
     * @param end Defaults to Infinity
     * @param startInclusive Defaults to true unless start is -Infinity
     * @param endInclusive Defaults to false.
     * @throws SyntaxError if start is a malformed string.
     * @throws RangeError If:
     *     * start = Infinity or end = -Infinity
     *     * start = -Infinity and startInclusive is true
     *     * end   = Infinity and endInclusive is true
     */
    constructor(
        start: number|string=-Infinity,
        end: number=Infinity,
        startInclusive: boolean|null=null,
        endInclusive: boolean|null=false
    ) {
        if ('string' == typeof start) {

            const matchArray = start.match(rangeRegEx);
            if (null == matchArray) {
                throw new SyntaxError('Invalid range string');
            }

            const groups = matchArray?.groups || {};
            start = parseFloat(groups['start']);
            end   = parseFloat(groups['end']);
            startInclusive = groups['sartInc'] ? '[' === groups['sartInc'] : null;
            endInclusive   = groups['endInc']  ? ']' === groups['endInc']  : null;

            if (!isFinite(start)) {
                start = -Infinity;
            }
            if (!isFinite(end)) {
                end = Infinity;
            }
        }
        if (null != start && !isNaN(start)) {
            this.start = start;
        }
        if (null != startInclusive) {
            this.startInclusive = !!startInclusive;
        } else {
            this.startInclusive = -Infinity !== this.start;
        }
        if (null != end && !isNaN(end)) {
            this.end = end;
        }
        if (null != endInclusive) {
            this.endInclusive = !!endInclusive;
        }
        if (Infinity === this.start) {
            throw new RangeError('Can not start from Infinity');
        }
        if (-Infinity === this.end) {
            throw new RangeError('Can not end at -Infinity');
        }
        if (this.start > this.end) {
            this.start = this.end = 0;
            this.startInclusive = this.endInclusive = false;
            return;
        }
        if ((this.start === this.end) && !(this.startInclusive && this.endInclusive)) {
            this.start = this.end = 0;
            this.startInclusive = this.endInclusive = false;
            return;
        }

        if (   (-Infinity === this.start && this.startInclusive)
            || (Infinity  === this.end   && this.endInclusive  ) )
        {
            throw new RangeError('Unbound closed endpoint');
        }
    }

    isEmpty() {
        return (this.start === this.end && !(this.startInclusive || this.endInclusive)) || this.start > this.end;
    }

    toString() {
        return `${this.startInclusive?'[':'('}${-Infinity === this.start?'':this.start},${Infinity === this.end?'':this.end}${this.endInclusive?']':')'}`;
    }

    valueOf() {
        return this.toString();
    }

    /**
     * A method that converts an object to a corresponding primitive value.
     * Called by the ToPrimitive abstract operation.
     * @param hint
     */
    [Symbol.toPrimitive](_hint: 'number'|'string'|'default') {
        // if ('number' == hint) {
        //     if (this.start == this.end) {
        //         return this.startInclusive ? this.start : NaN;
        //     }
        // }

        return this.toString();
    }

    /**
     * @param r
     * @returns whether r intersects with this NumberRange.
     */
    intersects(r: NumberRange) {
        let comparison = compareNumbers(this.end, r.start);
        if (0 === comparison && !(this.endInclusive && r.startInclusive)) {
            comparison = -1;
        }
        if (comparison < 0) {
            // this ends before r starts.
            return false;
        }

        // this ends after r starts
        comparison = compareNumbers(this.start, r.end);
        if (0 === comparison && !(this.startInclusive && r.endInclusive)) {
            comparison = 1;
        }

        // if true, this starts before r ends
        return comparison < 1;
    }

    /**
     * @param n
     * @returns A number that is negative, zero, or positive if n is before,
     *          in, or after this NumberRange on the number line, respectively.
     *          If this.isEmpty(), then 1 is returned.
     * @throws Error if n is null, undefined, or is NaN.
     */
    compareNumber(n: number) {
        if (null == n || isNaN(n)) {
            throw new Error('invalid number');
        }
        if (this.isEmpty()) {
            return 1;
        }
        if (-Infinity === n) {
            return -Infinity === this.start ? 0 : -1;
        }
        if (Infinity === n) {
            return Infinity === this.end ? 0 : 1;
        }

        const endCmp   = this.endInclusive   ? lte : lt;
        const startCmp = this.startInclusive ? lte : lt;

        return endCmp(n, this.end) ?
            (startCmp(this.start, n) ? 0 : -1) : 1;
    }

    /**
     * Compares a NumberRange to this, by first comparing start values, then
     * end values if needed. Empty ranges sort lower than non-empty ranges.
     *
     * @param r
     * @returns A number that is negative, zero, or positive if this sorts
     *          before, equally, or after r, respectively.
     */
    compareTo(r: NumberRange) {
        const rIsEmpty = r.isEmpty();
        if (this.isEmpty()) {
            return rIsEmpty ? 0 : -1; 
        } else if (rIsEmpty) {
            return 1;
        }

        let comparison = compareNumbers(this.start, r.start);

        if (0 === comparison) {
            if (-Infinity !== r.start && this.startInclusive !== r.startInclusive) {
                return this.startInclusive ? -1 : 1;
            }

            comparison = compareNumbers(this.end, r.end);

            if (0 === comparison && Infinity !== r.end && this.endInclusive !== r.endInclusive) {
                comparison = this.endInclusive ? 1 : -1;
            }
        }

        return comparison;
    }

    /**
     * Compare function for NumberRange objects.  Suitable for use with Array.sort().
     * @param r1
     * @param r2
     * @returns A number that is negative, zero, or positive if r1 sorts
     *          before, equally, or after r2, respectively.
     */
    static compare(r1: NumberRange, r2: NumberRange) {
        return r1.compareTo(r2)
    }

    /**
     * Creates a NumberRange from a representative string. The string is two
     * comma separated numbers, and is surrouned by square brackets or
     * parentheses depending on whether the endpoints are included. Missing
     * numbers represent unbounded endpoints.
     * Empty ranges are recognized and always result in (0,0); This happens when
     * start > end, or start == end and either endpoint is open.
     * Examples:
     * | rangeStr | Description  | Notes |
     * |----------|--------------|-------|
     * | (0,1)    | 0 <  x <  1  | exclusive (open) endpoints |
     * | [0,1]    | 0 <= x <= 1  | inclusive (closed) endpoints |
     * | (, 1]    | x <= 1       | unbound endpoint must be open |
     * | 0, 1     | 0 <= x < 1   | default to cosed-open |
     * | [1,1]    | x == 1       | single point |
     * | (0,0)    | ∅            | no values in range. Empty set |
     * | (1,0)    | ∅            | no values in range. Empty set |
     * | [1,1)    | ∅            | no values in range. Empty set |
     *
     * @param {string} rangeStr
     * @returns NumberRange
     */
    static parse(rangeStr: string) {
        return new NumberRange(rangeStr);
    }

    /**
     * cast something to a NumberRange
     * @param obj Value to cast to a NumberRange
     *     * if obj is a string, then this method acts like parse(obj)
     *     * if obj is a finite number, then a single point range is returned
     *     * if obj is a length 2 array, then the closed-closed range
     *       created by casting the entries to numbers is returned
     *     * if obj is a NumberRange, null, or undefined then obj is returned
     * @returns NumberRange|null|undefined
     * @throws SyntaxError if obj is a malformed string.
     * @throws RangeError If:
     *     * start = Infinity or end = -Infinity
     *     * start = -Infinity and startInclusive is true
     *     * end   = Infinity and endInclusive is true
     * @throws TypeError if obj is not a value that can be cast to a NumberRange
     */
    static of(obj: any): NumberRange | null | undefined {
        if (null == obj) {
            return obj;
        }
        switch (typeof obj) {
            case 'string': return this.parse(obj);
            case 'number': return new NumberRange(obj, obj, true, true);
            case 'object':
                if (obj instanceof NumberRange) {
                    return obj;
                }
                if (Array.isArray(obj) && obj.length === 2) {
                    return new NumberRange(Number(obj[0]), Number(obj[1]), true, true);
                }
        }

        throw new TypeError("Can't convert value to NumberRange");
    }

    /**
     * Collapse intersecting or adjacent ranges
     * @param ranges 
     */
    static reduce(ranges: NumberRange[]) {
        ranges = ranges.sort(NumberRange.compare);
        for (let i = ranges.length - 2; i >= 0; i = i -1) {
            if (ranges[i+1].isEmpty()) {
                ranges.splice(i+1, 1);
                continue;
            }
            if (ranges[i].isEmpty()) {
                ranges.splice(i, 1);
                continue;
            }
            if (ranges[i].intersects(ranges[i+1])) {
                const endCmp = compareNumbers(ranges[i].end, ranges[i+1].end);
                // values if range[i].end < range[i+1].end
                let end = ranges[i+1].end;
                let endInclusive = ranges[i+1].endInclusive;
                if (endCmp > 0) {
                    end = ranges[i].end;
                    endInclusive = ranges[i].endInclusive;
                } else if (endCmp === 0 && ranges[i+1].endInclusive !== ranges[i].endInclusive) {
                    endInclusive = true;
                }
                ranges[i] = new NumberRange(
                    ranges[i].start, end, ranges[i].startInclusive, endInclusive
                );
                ranges.splice(i+1, 1);
            } else if (ranges[i].end === ranges[i+1].start && ranges[i].endInclusive !== ranges[i+1].startInclusive) {
                ranges[i] = new NumberRange(
                    ranges[i].start, ranges[i+1].end, ranges[i].startInclusive, ranges[i+1].endInclusive
                );
                ranges.splice(i+1, 1);
            }
        }

        if (ranges[0]?.isEmpty?.()) {
            ranges.splice(0,1);
        }

        return ranges;
    }
}