import { AccessToken } from "@okta/okta-auth-js";
import cfg from "./snorcl-config.json";

export interface SnorclWebRequest {
    oktaUser: string,
    snorclInputs: SnorclJson[]
}

export interface SampleDateRange {
    start_date: string,
    end_date: string,
    scheduled_days: [number, ...number[]]
}

export interface LightBox {
    name: string,
    strain_barcodes: string[],
    strain_conditions: string[],
    toc_date_range?: SampleDateRange,
    tn_date_range?: SampleDateRange,
    fame_date_range?: SampleDateRange
}

export interface SnorclJson {
    user_barcode: string,
    project: string,
    sample_time: string,
    sample_volume_ml: number
    exp: {
        name: string,
        light_boxes: LightBox[]
    }
}

export async function invokeSnorcl(
    snorclWebRequest: SnorclWebRequest,
    accessToken: AccessToken
): Promise<void> {
    
    const response = await fetch(cfg.SNORCL_INVOKE_URL, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Authentication': `Bearer ${accessToken.accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(snorclWebRequest)
    });

    if (!response.ok) {
        alert(`The SNORCL request returned an error`)
    }
}