import { ProjectType } from "./snorclInfo";

export const ProjectTypes: ProjectType[] = [
    'ALBIN',  // LBA
    'ALBOUT', // PondSims, SD_SCALEUP
    'GENERAL', // general, PLANT??
    'BSCBIO'
];

export const SampleNames = [
    'TOC',
    'TN',
    'Fame'
] as const;
export type SampleName = (typeof SampleNames)[number];

export const PhaseNames = [
    'Growth',
    'Induction'
] as const;
export type PhaseName = (typeof PhaseNames)[number];

export class Sample {
    public constructor(
        public name: SampleName,
        public collectionDays: [number, ...number[]]
    ) {
        for (const d of collectionDays) {
            if (d < 0 || !Number.isInteger(d)) {
                throw new Error(`Invalid collection day "${d}"`);
            }
        }
    }

    toJSON() {
        return {
            name: this.name,
            collectionDays: this.collectionDays
        }
    }
}

export class Phase {
    public constructor(
        public name:    PhaseName,
        public numDays: number,
        public samples: [Sample, ...Sample[]],
        public sampleTime: `${number}${number}:${number}${number}` = '12:00'
    ){
        const [hrStr, minStr] = sampleTime.split(':').map(n=>parseInt(n));
        if (isNaN(hrStr) || hrStr < 0 || 24 <= hrStr ||
            isNaN(minStr) || minStr < 0 || 60 <= minStr)
        {
            throw new Error(`Invalid sampleTime "${sampleTime}"`);
        }
        if (numDays < 0 || !Number.isInteger(numDays)) {
            throw new Error(`Invalid numDays "${numDays}"`)
        }
        for (const sample of samples) {
            for (const d of sample.collectionDays) {
                if (numDays < d) {
                    throw new Error(`Invalid collection day. `);
                }
            }
        }
        if (new Set(samples.map(p=>p.name)).size !== samples.length) {
            throw new Error(`duplicate samples for phase "${name}"`);
        }
    }

    toJSON() {
        return {
            name: this.name,
            numDays: this.numDays,
            samples: this.samples.map(s=>s.toJSON()),
            sampleTime: this.sampleTime
        }
    }
}

export class SOP {
    public constructor(
        public name: string,
        public projectType: ProjectType,
        public phases: [Phase, ...Phase[]]
    ) {
        if (new Set(phases.map(p=>p.name)).size !== phases.length) {
            throw new Error(`duplicate phases for project type "${projectType}"`);
        }
    }

    toJSON() {
        return {
            name: this.name,
            projectType: this.projectType,
            phases: this.phases.map(p=>p.toJSON())
        }
    }
}