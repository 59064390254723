import { type OktaAuthOptions } from "@okta/okta-auth-js";
import cfg from "./snorcl-config.json";

const oktaAuthConfig: OktaAuthOptions = {
    issuer: cfg.REACT_APP_OKTA_BASE_URL,
    clientId: cfg.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    restoreOriginalUri: undefined
};

export default oktaAuthConfig;
