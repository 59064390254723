import { ErrorMessage, FieldInputProps } from "formik";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import Select from "react-select";
import { SnorclInfo } from "../libs/snorclInfo";
import { ExperimentForm, ExperimentFormValues } from "./ExperimentForm";
import Button from "react-bootstrap/esm/Button";
import Papa from 'papaparse';

import {type PhaseFormValues} from './ExperimentForm';
import { OverlayTrigger, ToggleButton, ToggleButtonGroup, Tooltip } from "react-bootstrap";

interface SampleMap {
    tube_num: string,
    rack: string,
    sample_id: string,
    experiment: string,
    sample_type: string
}

export interface TubeRackFormProps {
    values: ExperimentFormValues,
    phaseIndex: number,
    containerIndex: number,
    snorclInfo: SnorclInfo,
    siteOptions: {
        value: string;
        label: string;
    }[],
    strainOptions: {
        value: string;
        label: string;
    }[]
    getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const TubeRackForm: React.FC<TubeRackFormProps> = (
    {values, snorclInfo, phaseIndex, containerIndex, siteOptions, strainOptions, getFieldProps, setFieldValue}
) => {
    const c = values.phases[phaseIndex].containers[containerIndex];
    const stri = 0;
    const str = c.strains[stri]

    const addStrain = (containerIndex: number) => {
        values.phases[phaseIndex].containers[containerIndex].strains.push({
            name: '',
            replicates: 1
        });
        setFieldValue('phases', [...values.phases]);
    };

    const onFileSelected = (files?: FileList|null) => {
        let file = files?.item(0);
        // console.log('selected', file?.name);
        if (!file) { 
            return;
        } else if ((files?.length??2) > 1) {
            return alert('Please select a single file');
        }

        if (!file.name.toLowerCase().endsWith('.csv')) {
            return alert('Please select a .csv file');
        }

        file.text().then(txt => {
            Papa.parse(txt, {
                header: true,
                skipEmptyLines: 'greedy',
                complete: (results: Papa.ParseResult<unknown>) => {
                    // console.log(results);
                    let expectedFields = ['tube_num','rack','sample_id','experiment','sample_type'];
                    let actualFields = new Set(results.meta.fields ?? []);
                    let missingFields: string[] = [];
                    for (let expectedField of expectedFields ) {
                        if (!actualFields.has(expectedField)) {
                            missingFields.push(expectedField);
                        }
                    }
                    if (missingFields.length) {
                        return alert(`File is missing column(s) ${missingFields.join(', ')}`);
                    }
                    const sampleMap = results.data as SampleMap[];
                    // console.log(sampleMap);

                    const containers: PhaseFormValues["containers"] = [];
                    const knownStrains = new Set(snorclInfo.assays.TuBE.strains);
                    const numRegEx = /(?<num>\d+)/;
                    const unknownStrains = new Set<string>();
                    const experimentNums = new Set<number>();
                    let   rack: number      = NaN;
                    let   tube: number      = NaN;
                    const rackCounts = Array<number>(12).fill(0);

                    try {
                        sampleMap.forEach((row, ri) => {
                            const tubeNum   = parseInt(numRegEx.exec(row.tube_num)?.groups?.['num'] as string || '0') || tube;
                            if (!tubeNum) {
                                throw new Error('At least the first row of data must specify a tube_num');
                            }
                            tube = tubeNum;

                            const rackNum   = parseInt(numRegEx.exec(row.rack)?.groups?.['num'] as string || '0') || rack;
                            if (!rackNum) {
                                throw new Error('At least the first row of data must specify a rack');
                            }
                            if (rackNum < 1 || 12 < rackNum) {
                                throw new Error(`Invalid rack in row ${ri+2}`);
                            }
                            rack        = rackNum;
                            rackCounts[rack-1] += 1;
                            if (rackCounts[rack-1] > 8) {
                                throw new Error(`Too many samples in rack ${rack}. (exceeds 8 at row ${ri+2})`)
                            }

                            const site      = 'TB';
                            const sampleType = (row.sample_type || 'experiment').trim().toLowerCase();
                            const strainNum = parseInt(numRegEx.exec(row.sample_id)?.groups?.['num'] as string);
                            const expNum    = parseInt(numRegEx.exec(row.experiment)?.groups?.['num'] as string);
                            if (!(['experiment', 'control'].includes(sampleType))) {
                                throw new Error(`Invalid sample_type in row ${ri+2}`);
                            }
                            if (expNum) {
                                experimentNums.add(expNum);
                            }
                            if (!knownStrains.has(`STR${strainNum}`)) {
                                unknownStrains.add(row.sample_id);
                            }
                            let container = containers.find(c => c.name === site)
                            if (!container) {
                                container = {
                                    name: site,
                                    strains: []
                                };
                                containers.push(container);
                            }
                            container.strains.push({
                                name: `STR${strainNum}`,
                                replicates: 1,
                                notes: `${sampleType}: rack ${rackNum}: ${tube}`
                            });
                        });
                    } catch (err) {
                        return alert(err instanceof Error ? err.message : String(err))
                    }

                    if (experimentNums.size > 1) {
                        return alert('Only 1 experiment per file is supported.');
                    } else if (experimentNums.size === 1) {
                        const expNum = experimentNums.values().next().value as number;
                        if (expNum <= snorclInfo.assays.TuBE.maxNumber) {
                            return alert(`experiment number ${expNum} has already been used`)
                        }
                        setFieldValue('num', expNum);
                    }

                    if (unknownStrains.size) {
                        return alert(`Unrecognized strains: ${Array.from(unknownStrains).join(', ')}`)
                    }

                    let dropContainerDiv = document.getElementById('dropContainer');
                    if (dropContainerDiv) {
                        dropContainerDiv.style.visibility = 'hidden'
                    }
                    setFieldValue(`phases[${phaseIndex}].containers`, containers);
                    //setFieldValue('phases', [...values.phases])
                    // console.log(containers);
                },
                error(error: any, file: any) {
                    console.error(`Error while parsing file ${file?.name || ''}`, error);
                },
            });
        })
    }

    const makeRacks = () => {
        const rackNumRegEx = /rack (?<rackNum>\d+)[^\d]*(?<tubeNum>\d+)/;
        const racks : JSX.Element[][] = [];
        let rack: JSX.Element[];
        c.strains.forEach((strain, strainIdx) => {
            const rackNum = parseInt(rackNumRegEx.exec(strain.notes ?? '')?.groups?.['rackNum']??'') || 1
            const tubeNum = parseInt(rackNumRegEx.exec(strain.notes ?? '')?.groups?.['tubeNum']??'') || 1
            while (racks.length < rackNum) {
                rack = [];
                racks.push(rack)
            }
            rack = racks[rackNum-1]; // convert to zero based index
            rack.push(
                <ToggleButton
                    key={`${c.name}-${strainIdx}`}
                    value={strainIdx}
                    variant='empty'
                >
                    <OverlayTrigger
                        key={`${c.name}-${strainIdx}-ot`}
                        placement="top"
                        delay={200}
                        overlay={
                            <Tooltip id={`${c.name}-${strainIdx}-tt`}>
                                {strain.name || 'Empty'}
                            </Tooltip>
                        }
                    >
                        <div style={{width:'20px'}}>{tubeNum}</div>
                    </OverlayTrigger>
                </ToggleButton>)
        });

        return racks;
    }
    
    // console.log('container', c)

    return (0 === containerIndex && !(c?.name)) ? (<div key={containerIndex}>
        <Row><Col><Card className="light-background" style={{paddingTop: '20px'}}
                onDragOver={e => e.preventDefault()} onDragEnter={e => e.preventDefault()}
                onDrop={evt => {
                    onFileSelected(evt.dataTransfer.files);
                    evt.preventDefault();
                }}><Container>
            <Row><Col>
            <div id="dropContainer" style={{justifyContent: 'center', display: 'flex'}}>
                <input type="file" id="selectedFile" style={{display: "none"}}
                    onChange={(e) => onFileSelected(e.target.files)}
                    accept=".csv"
                />
                <Button variant="primary"
                    onClick={()=>{
                        var fi = document.getElementById('selectedFile') as HTMLInputElement;
                        if (fi) {
                            fi.value = '';
                            fi.click();
                        }
                    }}
                >Browse</Button>
                <span style={{alignContent: 'center', display: 'flex', flexWrap: 'wrap'}}>
                    &nbsp;or drop a sample map csv file here.
                </span>
            </div>
            </Col></Row><br/>
            <Row><Col></Col></Row>
        </Container></Card></Col></Row>
    </div>) : (<div key={containerIndex}>
        <Row><Col><Card className="light-background" style={{paddingTop: '20px'}}><Container>
            <Card.Title>Racks and Tube number</Card.Title>
            {
                makeRacks().map((rack, rackIndex) => (
                    <Row
                        key={`${c.name}-${rackIndex}-row`}
                    ><Col sm='auto'><div style={{width:'80px'}}>&nbsp;&nbsp;{`Rack ${rackIndex+1}`}</div></Col><Col>
                        <ToggleButtonGroup
                            key={`${c.name}-${rackIndex}-tbg`}
                            type="checkbox" size='sm'  value={[]}
                        >{rack}</ToggleButtonGroup></Col></Row>
                ))
            }<br/>
        </Container></Card></Col></Row><br/>
    </div>);
};