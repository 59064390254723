

export const toDateInputString = (d: Date|string): string => {
    if (typeof d === 'string') {
        return d;
    }
    return `${d.getFullYear()}-${String(d.getMonth()+1).padStart(2,'0')}-${String(d.getDate()).padStart(2,'0')}`;
}

export const toSnorclTimeString = (t: string): string => {
    return t.split(':').slice(0,2).join(':');
}

export const fromDateInputString = (d: Date|string): Date => {
    if (d instanceof Date) {
        return d;
    }
    let parts = d.split('-').map(part=>parseInt(part));
    
    return new Date(
        parts[0],
        parts[1] - 1,
        parts[2]
    );
}

/**
 * Gets an environment variable, if set, or a default value.
 * 
 * @param name The name of the environment variable.
 * @param defaultValue A value to return if `name` is not set.
 * @returns The value of the environment variable named by `name`
 *          if present, otherwise `defaultValue`.
 * @throws ReferenceError if niether the environment variable nor
 *         `defaultValue` are set.
 */
export const getEnv = (name: string, defaultValue?: string): string => {

    const val = process.env[name] || defaultValue;

    if (!val) {
        throw new ReferenceError(`Environment variable "${name}" not set.`);
    }

    return val;
};

/**
 * Generator function for yielding a range of numbers.
 * @example
 * ```typescript
 * for (let n of range(10,6,2)) { console.log(n); }
 * // prints '6' then '8'
 * ```
 * @param stop  Ending number (exclusive).
 * @param start Starting number (inclusive). Defaults to 0; 
 * @param step  Difference between each yielded number. Defaults to 1.
 */
export function* range(
    stop:  number,
    start: number=0,
    step:  number=1
): Generator<number,void,void> {
    for (let i = start; i < stop; i = i + step) {
        yield i;
    }
};

/**
 * Like Array.forEach, but for Iterators.
 * @example
 * ```typescript
 * forEach(range(10,6,2), console.log);
 * // prints '6' then '8'
 * ```
 */
export function forEach<T>(
    iterator: Iterator<T,void, void>,
    callback: (item: T) => void
): void {
    for(let element = iterator.next();
        !element.done;
        element = iterator.next()
    ) {
        callback(element.value);
    }
};

/**
 * Like Array.map, but for Iterators.
 * @example
 * ```typescript
 * forEach(
 *     map(range(10,6,2), n => n*10),
 *     console.log
 * );
 * // prints '60' then '80'
 * ```
 * @returns A Generator of mapped values.
 */
export function* map<T, U>(
    iterator: Iterator<T,void, void>,
    mapper: (item: T) => U
): Generator<U, void, void> {
    for(let element = iterator.next();
        !element.done;
        element = iterator.next()
    ) {
        yield mapper(element.value);
    }
};

/**
 * Like Array.map, but for Iterators.
 * @example
 * ```typescript
 * console.log(
 *     mapToArray(range(10,6,2), n => n*10)
 * );
 * // prints '[ 60, 80 ]'
 * ```
 * @returns A array of mapped values.
 */
export function mapToArray<T, U>(
    iterator: Iterator<T,void, void>,
    mapper: (item: T, index: number) => U
): U[] {
    const mapped: U[] = [];
    for(let element = iterator.next();
        !element.done;
        element = iterator.next()
    ) {
        mapped.push(mapper(element.value, mapped.length));
    }

    return mapped;
};

/**
 * A no-op function that may be awaited for a specified time.
 * @param ms Time to await in milliseconds.
 * @returns A promise that resolves after `ms` milliseconds.
 */
export async function sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }