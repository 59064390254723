import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import React from 'react';
import { Navigate, Outlet, Route, Routes as ReactRoutes, useNavigate } from 'react-router-dom';
import oktaAuthConfig from "../libs/config";
import Home from "./Home";
import Error from "./Error";
import Profile from "./Profile";
import Navbar from "./Navbar";
import SecureRoutes from './SecureRoutes';

const Routes: React.FC = () => {
    const navigate = useNavigate();
    const restoreOriginalUri = async (_oktaAuth: any,  originalUri?: string) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };
    const oktaAuth = new OktaAuth(oktaAuthConfig);

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <ReactRoutes>
                <Route path="/" element={<div><Navbar/><Outlet/></div>}>
                    <Route       index           element={<Navigate to="/home" replace/>} />
                    <Route       path="home"     element={<Home/>} />
                    <Route       path="error"    element={<Error/>} />
                    <Route path="profile"  element={<SecureRoutes/>}>
                        <Route path="" element={<Profile/>} />
                    </Route>
                    <Route path="login/callback" element={<LoginCallback/>} />
                </Route>
                <Route path="*" element={<div><h1>Not Found</h1></div>} />
            </ReactRoutes>
        </Security>
    );
};

export default Routes;